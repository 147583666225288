import { makeIcon } from 'bootstrap-vue/src/icons/helpers/make-icon'

export const BIconAbort = makeIcon(
  'Abort',
  '<path d="M8.87,8l5.22,5.22l-0.87,0.87L8,8.87l-5.22,5.22l-0.87-0.87L7.13,8L1.91,2.78l0.87-0.87L8,7.13l5.22-5.22l0.87,0.87L8.87,8z"/>'
)

export const BIconArrowDropdown = makeIcon(
  'ArrowDropdown',
  '<path d="M13.03,5.22c-0.29-0.29-0.77-0.29-1.06,0L8,9.19L4.03,5.22c-0.29-0.29-0.77-0.29-1.06,0s-0.29,0.77,0,1.06l4.5,4.5C7.62,10.93,7.81,11,8,11c0.19,0,0.38-0.07,0.53-0.22l4.5-4.5C13.32,5.99,13.32,5.51,13.03,5.22z"/>'
)

export const BIconArrowLeft = makeIcon(
  'ArrowLeft',
  '<path d="M1.08,7.81l3.06-3.06c0.06-0.06,0.13-0.09,0.2-0.09s0.14,0.03,0.19,0.08c0.11,0.11,0.11,0.28,0,0.39L1.97,7.69h12.76c0.15,0,0.27,0.12,0.27,0.27c0,0.15-0.12,0.27-0.27,0.27H1.9l2.63,2.63c0.11,0.11,0.11,0.28,0,0.39c-0.11,0.11-0.28,0.11-0.39,0L1.08,8.19C0.97,8.09,0.97,7.91,1.08,7.81z"/>'
)

export const BIconArrowRight = makeIcon(
  'ArrowRight',
  '<path d="M14.92,8.19l-3.06,3.06c-0.05,0.05-0.12,0.08-0.19,0.08s-0.14-0.03-0.19-0.08c-0.11-0.11-0.11-0.28,0-0.39l2.56-2.56H1.27C1.12,8.31,1,8.19,1,8.03s0.12-0.27,0.27-0.27H14.1l-2.63-2.63c-0.11-0.11-0.11-0.28,0-0.39c0.11-0.11,0.28-0.11,0.39,0l3.06,3.06C15.03,7.91,15.03,8.09,14.92,8.19z"/>'
)

export const BIconCalendar = makeIcon(
  'Calendar',
  '<path d="M1.03,13.77c0,0.68,0.55,1.23,1.23,1.23h11.48c0.68,0,1.23-0.55,1.23-1.23V6.8H1.03V13.77z M10.05,8.03c0-0.23,0.18-0.41,0.41-0.41h1.64c0.23,0,0.41,0.18,0.41,0.41v1.64c0,0.23-0.18,0.41-0.41,0.41h-1.64c-0.23,0-0.41-0.18-0.41-0.41V8.03z M10.05,11.31c0-0.23,0.18-0.41,0.41-0.41h1.64c0.23,0,0.41,0.18,0.41,0.41v1.64c0,0.23-0.18,0.41-0.41,0.41h-1.64c-0.23,0-0.41-0.18-0.41-0.41V11.31z M6.77,8.03c0-0.23,0.18-0.41,0.41-0.41h1.64c0.23,0,0.41,0.18,0.41,0.41v1.64c0,0.23-0.18,0.41-0.41,0.41H7.18c-0.23,0-0.41-0.18-0.41-0.41V8.03z M6.77,11.31c0-0.23,0.18-0.41,0.41-0.41h1.64c0.23,0,0.41,0.18,0.41,0.41v1.64c0,0.23-0.18,0.41-0.41,0.41H7.18c-0.23,0-0.41-0.18-0.41-0.41V11.31z M3.49,8.03c0-0.23,0.18-0.41,0.41-0.41h1.64c0.23,0,0.41,0.18,0.41,0.41v1.64c0,0.23-0.18,0.41-0.41,0.41H3.9c-0.23,0-0.41-0.18-0.41-0.41V8.03z M3.49,11.31c0-0.23,0.18-0.41,0.41-0.41h1.64c0.23,0,0.41,0.18,0.41,0.41v1.64c0,0.23-0.18,0.41-0.41,0.41H3.9c-0.23,0-0.41-0.18-0.41-0.41V11.31z M11.28,3.46V1.82C11.28,1.37,11.65,1,12.1,1c0.45,0,0.82,0.37,0.82,0.82v1.64c0,0.45-0.37,0.82-0.82,0.82C11.65,4.28,11.28,3.91,11.28,3.46z M14.97,3.87v2.11H1.03V3.87c0-0.68,0.55-1.23,1.23-1.23v0.82c0,0.9,0.74,1.64,1.64,1.64s1.64-0.74,1.64-1.64V2.64h4.92v0.82c0,0.9,0.74,1.64,1.64,1.64s1.64-0.74,1.64-1.64V2.64C14.42,2.64,14.97,3.19,14.97,3.87z M3.08,3.46V1.82C3.08,1.37,3.45,1,3.9,1c0.45,0,0.82,0.37,0.82,0.82v1.64c0,0.45-0.37,0.82-0.82,0.82C3.45,4.28,3.08,3.91,3.08,3.46z"/>'
)

export const BIconCart = makeIcon(
  'Cart',
  '<path d="M12.03,5.37c0.01-0.12,0.04-0.24,0.04-0.37c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,0.13,0.03,0.25,0.04,0.37H2L3.54,15h8.98L14,5.37H12.03z M6.06,5c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.13-0.05,0.25-0.08,0.37H6.14C6.11,5.25,6.06,5.13,6.06,5z"/>'
)

export const BIconCheck = makeIcon(
  'Check',
  '<path d="M14.87,2.78l-9.02,11.2c-0.11,0.13-0.27,0.21-0.44,0.22c-0.01,0-0.02,0-0.03,0c-0.16,0-0.31-0.06-0.43-0.18l-3.78-3.81c-0.23-0.24-0.23-0.62,0-0.85c0.24-0.23,0.62-0.23,0.85,0l3.31,3.33l8.6-10.68c0.21-0.26,0.59-0.3,0.84-0.09C15.03,2.14,15.08,2.52,14.87,2.78z"/>'
)

export const BIconClose = makeIcon(
  'Close',
  '<path d="M14.76,13.6c0.32,0.32,0.32,0.84,0,1.16C14.6,14.92,14.39,15,14.18,15s-0.42-0.08-0.58-0.24L8,9.16l-5.6,5.6C2.24,14.92,2.03,15,1.82,15s-0.42-0.08-0.58-0.24c-0.32-0.32-0.32-0.84,0-1.16L6.84,8l-5.6-5.6c-0.32-0.32-0.32-0.84,0-1.16s0.84-0.32,1.16,0L8,6.84l5.6-5.6c0.32-0.32,0.84-0.32,1.16,0s0.32,0.84,0,1.16L9.16,8L14.76,13.6z"/>'
)

export const BIconEdit = makeIcon(
  'Edit',
  '<path d="M9.65,3.34l-7.7,7.7c-0.04,0.04-0.07,0.09-0.08,0.14l-0.85,3.43c-0.03,0.1,0,0.21,0.08,0.29c0.06,0.06,0.13,0.09,0.21,0.09c0.02,0,0.05,0,0.07-0.01l3.43-0.85c0.05-0.01,0.1-0.04,0.14-0.08l7.7-7.7L9.65,3.34z M14.56,2.29L13.7,1.44c-0.57-0.57-1.57-0.57-2.14,0L10.5,2.49l3,3l1.05-1.05C14.84,4.15,15,3.77,15,3.37S14.84,2.58,14.56,2.29z"/>'
)

export const BIconFilter = makeIcon(
  'Filter',
  '<path d="M2.82,4.53c0.94,0,1.77-0.65,2-1.55h9.9c0.28,0,0.52-0.23,0.52-0.52S15,1.94,14.71,1.94h-9.9c-0.23-0.9-1.06-1.55-2-1.55c-1.14,0-2.07,0.93-2.07,2.07S1.68,4.53,2.82,4.53z M2.82,3.49c-0.57,0-1.03-0.46-1.03-1.03s0.46-1.03,1.03-1.03s1.03,0.46,1.03,1.03S3.39,3.49,2.82,3.49z M13.16,11.25c-0.94,0-1.77,0.65-2,1.55h-9.9c-0.29,0-0.52,0.23-0.52,0.52c0,0.28,0.23,0.52,0.52,0.52h9.9c0.23,0.9,1.06,1.55,2,1.55c1.14,0,2.07-0.93,2.07-2.07S14.3,11.25,13.16,11.25z M13.16,12.29c0.57,0,1.03,0.46,1.03,1.03c0,0.57-0.46,1.03-1.03,1.03s-1.03-0.46-1.03-1.03C12.13,12.75,12.59,12.29,13.16,12.29z M14.71,7.37h-4.6C9.9,6.41,9.05,5.71,8.04,5.71s-1.86,0.7-2.07,1.66H1.27c-0.29,0-0.52,0.23-0.52,0.52s0.23,0.52,0.52,0.52H6c0.25,0.91,1.08,1.55,2.04,1.55c0.96,0,1.79-0.64,2.04-1.55h4.63c0.28,0,0.52-0.23,0.52-0.52S15,7.37,14.71,7.37z M9.13,7.84c0,0.6-0.49,1.09-1.09,1.09c-0.6,0-1.09-0.49-1.09-1.09s0.49-1.09,1.09-1.09C8.64,6.75,9.13,7.24,9.13,7.84z"/>'
)

export const BIconHotline = makeIcon(
  'Hotline',
  '<path d="M15,2.31C15,9.33,9.32,15,2.31,15C2,15,1.74,14.79,1.67,14.49l-0.66-2.84c-0.07-0.31,0.09-0.63,0.38-0.75l3.06-1.31c0.27-0.11,0.58-0.04,0.77,0.19l1.36,1.66c2.14-1,3.86-2.75,4.85-4.85L9.77,5.22c-0.23-0.18-0.3-0.5-0.19-0.77L10.9,1.4c0.13-0.29,0.44-0.45,0.75-0.38l2.84,0.66C14.79,1.74,15,2.01,15,2.31z"/>'
)

export const BIconImagePlaceholder = makeIcon(
  'ImagePlaceholder',
  '<path d="M4.1,14.2l1.9,0.2l-3.1,0.6c-0.3,0.1-0.5-0.1-0.6-0.4L0.5,5.4C0.5,5.1,0.6,4.9,0.9,4.8l3.4-0.7l-0.8,9.3C3.5,13.8,3.7,14.2,4.1,14.2z M5.7,10.3l7.5,0.7l0.5-5.2l-2.4,1.8L8.8,6.3L5.8,8.1L5.7,10.3z M15.5,2.4l-1.1,12c0,0.2-0.2,0.4-0.4,0.4l-9.9-0.9c-0.2,0-0.4-0.2-0.4-0.4l1.1-12C4.8,1.2,5,1,5.3,1l9.9,0.9C15.4,1.9,15.5,2.1,15.5,2.4z M14.1,3.2l-8-0.7l-0.7,8l8,0.7L14.1,3.2z"/>'
)

export const BIconLoaderCircleBack = makeIcon(
  'LoaderCircleBack',
  '<path d="M8,0.5C3.9,0.5,0.5,3.9,0.5,8s3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5S12.1,0.5,8,0.5z M8,14.5c-3.6,0-6.5-2.9-6.5-6.5S4.4,1.5,8,1.5s6.5,2.9,6.5,6.5S11.6,14.5,8,14.5z"/>'
)

export const BIconLoaderCircleFront = makeIcon(
  'LoaderCircleFront',
  '<path class="loader-spinner" d="M14.3,8h1.4c0,4.3-3.4,7.7-7.7,7.7S0.3,12.3,0.3,8S3.7,0.3,8,0.3v1.4C4.5,1.7,1.7,4.5,1.7,8s2.8,6.3,6.3,6.3S14.3,11.5,14.3,8z"/>'
)

export const BIconLocation = makeIcon(
  'Location',
  '<path d="M9.16,10.56V15H6.7v-4.49C4.65,9.94,3.14,8.08,3.14,5.86C3.14,3.17,5.32,1,8,1s4.86,2.17,4.86,4.86C12.86,8.14,11.28,10.04,9.16,10.56z"/>'
)

export const BIconLogout = makeIcon(
  'Logout',
  '<path d="M8.87,1h-7C1.39,1,1,1.39,1,1.88v12.25C1,14.61,1.39,15,1.88,15h7c0.48,0,0.88-0.39,0.88-0.88V1.88C9.75,1.39,9.36,1,8.87,1L8.87,1z M7.13,8.87C6.64,8.87,6.25,8.48,6.25,8c0-0.48,0.39-0.88,0.88-0.88S8,7.52,8,8C8,8.48,7.61,8.87,7.13,8.87L7.13,8.87z M15,8l-3.06-2.62v1.75h-1.31v1.75h1.31v1.75L15,8z"/>'
)

export const BIconMinus = makeIcon(
  'Minus',
  '<path d="M14,9H2C1.45,9,1,8.55,1,8s0.45-1,1-1h12c0.55,0,1,0.45,1,1S14.55,9,14,9z"/>'
)

export const BIconPlus = makeIcon(
  'Plus',
  '<path d="M15,8c0,0.33-0.27,0.6-0.6,0.6H8.6v5.8C8.6,14.73,8.33,15,8,15s-0.6-0.27-0.6-0.6V8.6H1.6C1.27,8.6,1,8.33,1,8s0.27-0.6,0.6-0.6h5.8V1.6C7.4,1.27,7.67,1,8,1s0.6,0.27,0.6,0.6v5.8h5.8C14.73,7.4,15,7.67,15,8z"/>'
)

export const BIconPodcast = makeIcon(
  'Podcast',
  '<path d="M15,8.86v1.29c0,0.32-0.19,0.62-0.48,0.77l-0.39,0.19c-0.05,1.61-1.4,2.9-3.06,2.9h-0.66c-0.36,0-0.66-0.29-0.66-0.64V8.64C9.75,8.29,10.04,8,10.41,8h0.66c0.86,0,1.63,0.34,2.19,0.9V8.86c0-2.84-2.36-5.14-5.25-5.14s-5.26,2.3-5.26,5.14V8.9C3.31,8.34,4.08,8,4.94,8H5.6c0.36,0,0.66,0.29,0.66,0.64v4.71c0,0.36-0.29,0.64-0.66,0.64H4.94c-1.66,0-3.01-1.29-3.06-2.9L1.49,10.9C1.19,10.76,1,10.47,1,10.14V8.86C1,5.07,4.13,2,8,2S15,5.07,15,8.86z"/>'
)

export const BIconProfile = makeIcon(
  'Profile',
  '<path d="M15,14.01c0,0.02,0,0.03-0.01,0.05H1.01C1,14.04,1,14.03,1,14.01c0-2.89,1.75-5.37,4.25-6.43C4.79,6.98,4.5,6.25,4.5,5.44c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.81-0.29,1.55-0.75,2.14C13.25,8.65,15,11.12,15,14.01z"/>'
)

export const BIconQuestionmark = makeIcon(
  'Questionmark',
  '<path d="M8,0.5C3.86,0.5,0.5,3.86,0.5,8s3.36,7.5,7.5,7.5c4.14,0,7.5-3.36,7.5-7.5S12.14,0.5,8,0.5z M7.98,12.92c-0.55,0-1.01-0.45-1.01-1.01c0-0.55,0.45-1.01,1.01-1.01c0.55,0,1.01,0.45,1.01,1.01S8.54,12.92,7.98,12.92z M9.56,7.98C9.13,8.39,8.84,8.66,8.84,9.31v0.48c0,0.12-0.1,0.22-0.22,0.22h-1.2c-0.12,0-0.22-0.1-0.22-0.22V9.31c0-1.34,0.69-1.99,1.2-2.46c0.43-0.4,0.72-0.67,0.72-1.31c0-0.2,0-0.82-1.1-0.82c-1.03,0-1.07,0.57-1.09,0.81L6.89,6.02c0,0.06-0.03,0.11-0.08,0.15c-0.05,0.04-0.1,0.06-0.16,0.05L5.46,6.1c-0.12-0.01-0.21-0.12-0.2-0.24L5.29,5.4C5.4,3.99,6.48,3.08,8.03,3.08c0.81,0,1.5,0.23,1.98,0.66c0.49,0.44,0.75,1.06,0.75,1.81C10.76,6.86,10.06,7.51,9.56,7.98z"/>'
)

export const BIconReset = makeIcon(
  'Reset',
  '<path d="M10.4,2.7C9.3,2.3,8.2,2.1,7.1,2.3l0.5-1.8L4.2,3l3.3,2.5L7,3.6c1-0.2,2-0.1,3,0.3c2.8,1.1,4.1,4.2,3,7s-4.2,4.1-7,3s-4.1-4.2-3-7C3.2,6.4,3.4,6,3.7,5.6l-1-0.7c-0.4,0.5-0.7,1-0.9,1.6C0.5,9.8,2.2,13.7,5.6,15c3.4,1.3,7.3-0.3,8.6-3.7C15.5,7.9,13.8,4,10.4,2.7z"/>'
)

export const BIconSave = makeIcon(
  'Save',
  '<path d="M13.25,1h-0.88v5.25c0,0.48-0.39,0.88-0.88,0.88h-7c-0.48,0-0.88-0.39-0.88-0.88V1H2.75C1.78,1,1,1.78,1,2.75v10.5C1,14.22,1.78,15,2.75,15h10.5c0.97,0,1.75-0.78,1.75-1.75V2.75C15,1.78,14.22,1,13.25,1z M4.94,6.25h6.12c0.24,0,0.44-0.2,0.44-0.44V1h-7v4.81C4.5,6.05,4.7,6.25,4.94,6.25z M8.88,3.19c0-0.24,0.2-0.44,0.44-0.44s0.44,0.2,0.44,0.44v1.75c0,0.24-0.2,0.44-0.44,0.44s-0.44-0.2-0.44-0.44V3.19z"/>'
)

export const BIconSearch = makeIcon(
  'Search',
  '<path d="M14.79,13.64l-4.3-4.3c0.59-0.85,0.94-1.87,0.94-2.98c0-2.88-2.34-5.22-5.22-5.22S1,3.49,1,6.36s2.34,5.22,5.22,5.22c1.26,0,2.41-0.45,3.31-1.19l4.26,4.25c0.14,0.14,0.32,0.21,0.5,0.21s0.36-0.07,0.5-0.21C15.07,14.37,15.07,13.92,14.79,13.64z M2.42,6.36c0-2.09,1.7-3.8,3.8-3.8s3.79,1.7,3.79,3.8c0,2.09-1.7,3.8-3.79,3.8S2.42,8.46,2.42,6.36z"/>'
)

export const BIconSend = makeIcon(
  'Send',
  '<path d="M6.77,15.2l-0.71-5.06L0.9,9.06l14.19-8.13L6.77,15.2z M3.68,8.62l3.28,0.69l0.41,2.89l4.98-8.54L3.68,8.62z"/>'
)

export const BIconSocialFacebook = makeIcon(
  'SocialFacebook',
  '<path d="M6.28,15V8H4.5V5.67h1.78V4.14C6.28,2.17,7.16,1,9.64,1h1.84v2.34H9.91c-0.6-0.01-1.04,0.38-1.03,0.8s0,1.53,0,1.53h2.62L11.23,8H8.88v7H6.28z"/>'
)

export const BIconSocialGoogle = makeIcon(
  'SocialGoogle',
  '<path d="M8.1,6.61v2.77c0,0,2.73,0,3.84,0c-0.61,1.8-1.54,2.78-3.84,2.78c-2.33,0-4.15-1.86-4.15-4.16S5.77,3.84,8.1,3.84c1.23,0,2.03,0.43,2.76,1.02c0.58-0.58,0.54-0.66,2.02-2.04C11.61,1.69,9.94,1,8.1,1C4.18,1,1,4.13,1,8s3.18,7,7.1,7c5.86,0,7.29-5.03,6.82-8.39L8.1,6.61L8.1,6.61z"/>'
)

export const BIconSocialInstagram = makeIcon(
  'SocialInstagram',
  '<path d="M12.9,1H3.1C1.9,1,1,1.9,1,3.1v9.9C1,14.1,1.9,15,3.1,15h9.9c1.1,0,2.1-0.9,2.1-2.1V3.1C15,1.9,14.1,1,12.9,1z M8,11.7c-2,0-3.7-1.7-3.7-3.7C4.3,6,6,4.3,8,4.3S11.7,6,11.7,8C11.7,10.1,10,11.7,8,11.7z M12.5,4.3c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S13,4.3,12.5,4.3z M10.9,8c0,1.6-1.3,2.9-2.9,2.9S5.1,9.6,5.1,8S6.4,5.2,8,5.2S10.9,6.4,10.9,8z"/>'
)

export const BIconSocialLinkedin = makeIcon(
  'SocialLinkedin',
  '<path d="M1.18,5.66h3v9.02h-3C1.18,14.68,1.18,5.66,1.18,5.66z M15,14.68V9.51c0-2.77-1.48-4.06-3.45-4.06c-1.59,0-2.31,0.88-2.7,1.49V5.67h-3c0.04,0.85,0,9.02,0,9.02h3V9.65c0-0.27,0.02-0.54,0.1-0.73c0.22-0.54,0.71-1.1,1.54-1.1c1.09,0,1.52,0.83,1.52,2.04v4.82H15z M2.7,1.32C1.67,1.32,1,1.99,1,2.88c0,0.87,0.65,1.56,1.66,1.56h0.02c1.05,0,1.7-0.69,1.7-1.56C4.36,1.99,3.72,1.32,2.7,1.32z"/>'
)

export const BIconSocialTwitter = makeIcon(
  'SocialTwitter',
  '<path d="M15.5,4.1c-0.5,0.2-1.1,0.4-1.8,0.5c0.6-0.4,1.1-1,1.3-1.6c-0.6,0.3-1.3,0.6-1.9,0.7c-0.6-0.6-1.4-0.9-2.2-0.9c-1.7,0-3.1,1.3-3.1,3c0,0.2,0,0.5,0.1,0.7C5.3,6.2,3.1,5,1.5,3.2c-0.3,0.4-0.4,1-0.4,1.5c0,1,0.5,1.9,1.4,2.5c-0.5,0-1-0.1-1.4-0.4v0c0,1.4,1.1,2.6,2.5,2.9C3.3,9.8,3.1,9.9,2.8,9.9c-0.2,0-0.4,0-0.6-0.1c0.4,1.2,1.5,2.1,2.9,2.1c-1,0.8-2.4,1.3-3.8,1.3c-0.2,0-0.5,0-0.7,0c1.4,0.8,3,1.3,4.7,1.3c5.7,0,8.7-4.5,8.7-8.5c0-0.1,0-0.3,0-0.4C14.6,5.2,15.1,4.7,15.5,4.1"/>'
)

export const BIconSocialYoutube = makeIcon(
  'SocialYoutube',
  '<path d="M15.7,4c-0.2-0.7-0.7-1.3-1.4-1.5C13,2.3,8,2.3,8,2.3s-5,0-6.3,0.3C1.1,2.8,0.5,3.3,0.3,4C0,5.3,0,8,0,8s0,2.7,0.3,4c0.2,0.7,0.7,1.3,1.4,1.5C3,13.7,8,13.7,8,13.7s5,0,6.3-0.3c0.7-0.2,1.2-0.7,1.4-1.5C16,10.7,16,8,16,8S16,5.3,15.7,4z M6.9,11V5.6l4.3,2.7L6.9,11z"/>'
)

export const BIconTrash = makeIcon(
  'Trash',
  '<path d="M13.6,5l-0.3-1.1c-0.1-0.2-0.3-0.3-0.6-0.3H3.1c-0.2,0-0.5,0.1-0.6,0.3L2.4,5c-0.1,0.2,0,0.5,0.3,0.5h10.6C13.6,5.5,13.8,5.2,13.6,5L13.6,5z M4,14.9c0,0.3,0.3,0.6,0.8,0.6h6.4c0.3,0,0.7-0.3,0.8-0.6l1.1-8.5H3L4,14.9z M7,2c0-0.2,0.2-0.5,0.5-0.5h1.5c0.2,0,0.5,0.2,0.5,0.5v0.6h1V2c0-0.8-0.7-1.5-1.5-1.5H7.4C6.6,0.5,6,1.2,6,2v0.6h1C7,2.5,7,2,7,2z"/>'
)

export const BIconVideoPlay = makeIcon(
  'VideoPlay',
  '<path d="M8,16c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S12.41,16,8,16z M8,0.42c-4.18,0-7.58,3.4-7.58,7.58s3.4,7.58,7.58,7.58s7.58-3.4,7.58-7.58S12.18,0.42,8,0.42z M6.39,5.83v4.73l3.82-2.36L6.39,5.83z"/>'
)
