<script>
import i18n from '@/$plugins/i18n'
import { TRANSLATION_DEFAULT_REPLACER } from '@/assets/js/config/client'
import { unflatten, propertyByStringPath } from '@/assets/js/helper/object'

export default {
  props: {
    translations: {
      type: Object,
      default: () => ({})
    },
    translationsOnce: {
      type: Boolean,
      default: false
    },
    translationsPath: {
      type: String,
      default: null
    },
    useI18n: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    $TE (key = '') {
      return this.$T(key) !== key
    },
    $T (key = '', dynamicReplacer = {}) {
      const LOWER_KEY = key.toLowerCase()
      const REPLACER = Object.assign({}, TRANSLATION_DEFAULT_REPLACER, dynamicReplacer)
      const TRANSLATION_PROPERTY = propertyByStringPath(this.TRANSLATIONS, LOWER_KEY)

      let translation = TRANSLATION_PROPERTY === LOWER_KEY ? key : TRANSLATION_PROPERTY

      Object.keys(REPLACER)
        .forEach(rKey => {
          translation = translation.replace(`{${rKey}}`, REPLACER[rKey])
        })

      return translation
    }
  },
  data () {
    return {
      tPath: (this.translationsPath || this.$options.name || 'root').toLowerCase()
    }
  },
  beforeCreate () {
    const currentPropsData = this.$options.propsData || {}

    if (currentPropsData.useI18n) {
      this.tPath = (currentPropsData.translationsPath || this.$options.name || 'root').toLowerCase()

      if (!currentPropsData.translationsOnce || (currentPropsData.translationsOnce && !this.$te(this.tPath))) {
        const translationsData = currentPropsData.translations || {}
        const translations = unflatten(Object.keys(translationsData).reduce((t, tKey) => Object.assign(t, { [tKey.toLowerCase()]: translationsData[tKey] }), {}), '_')

        i18n.mergeLocaleMessage(i18n.locale, { [this.tPath]: translations })
      }
    } else {
      const rootPropsData = this.$root.$options.propsData || {}
      const translationsData = Object.assign({}, currentPropsData.translations || {}, rootPropsData.translations || {})

      this.TRANSLATIONS = unflatten(Object.keys(translationsData).reduce((t, tKey) => Object.assign(t, { [tKey.toLowerCase()]: translationsData[tKey] }), {}), '_')
    }
  }
}
</script>
