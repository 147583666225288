import Vue from 'vue'
import http from '@/$plugins/http'

import { normalize, unwrap } from '@/assets/js/helper/entity'

import ENTITY from './entity'

export default {
  state: Object.assign({}, ENTITY.state, {}),
  getters: Object.assign({}, ENTITY.getters, {
    getEntities: state => entityIds => state.entities.filter(e => entityIds.includes(e.value.id)),
    getEntitiesUnwrapped: state => entityIds => state.entities.filter(e => entityIds.includes(e.value.id)).map(unwrap)
  }),
  mutations: Object.assign({}, ENTITY.mutations, {
    setEntities (state, { key, entities = [] }) {
      entities
        .forEach(entity => {
          const normalizedEntity = normalize(entity, key)
          const updateIndex = state.entities.findIndex(e => e.value.id === normalizedEntity.value.id)
          const newIndex = state.entities.length

          if (normalizedEntity.value.id === state.self.value.id) state.self = normalizedEntity
          Vue.set(state.entities, updateIndex >= 0 ? updateIndex : newIndex, normalizedEntity)
        })
    }
  }),
  actions: Object.assign({}, ENTITY.actions, {
    getEntities (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getEntities`

      return function ({ state, getters, commit, dispatch }, entityIds = []) {
        dispatch('getDefinition')
        commit('setLoading', { key: isKey, loading: true, initial: state.entities.length === 0 })

        return new Promise(resolve => {
          http({
            method: 'post',
            url: `customer/api/${serverEntityName}/many`,
            data: {
              entityIds
            }
          })
            .then(response => {
              commit('setEntities', { key: entityKey, entities: response.data.result })
              resolve(getters.getEntities(entityIds))
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    }
  })
}
