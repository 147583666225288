import { validators } from '@/$plugins/vuelidate'

export const STOREMODULE_ENDPOINT_MAP = {
  changetracking: 'ChangeTracking',
  services: 'SubscriptionsAndServices',
  locations: 'Locations',
  locationaddresses: 'LocationAddress',
  users: 'Users',
  useraddresses: 'UserAddress',
  userprofessions: 'Professions',
  userlogins: 'UserLogins',
  userservices: 'UserSubscriptionsAndServices',
  usereducations: 'EasyLearnProfile',
  userlocations: 'UserLocations'
}

export const FILE_ENDPOINT_DEFAULT_URLS = {
  upload: '/customer/api/Files/create',
  download: '/customer/api/Files/',
  delete: '/customer/api/Files/'
}

/*
  for endpoint informations visit
    http://localhost:5000/search/swagger/index.html
  for resultType informations visit
    @/assets/js/helper/search.js => resultMapper:Function
    @/components/Search.vue => data () => resultsComponent:String
*/
export const SEARCH_TYPE_OPTIONS = {
  product: {
    isDefault: true,
    endpoint: 'Products',
    resultType: 'product'
  },
  marketingproduct: {
    endpoint: 'MarketingProducts',
    resultType: 'product'
  },
  education: {
    endpoint: 'Events',
    resultType: 'product',
    resultNoImg: true
  },
  document: {
    endpoint: 'DownloadCenter',
    resultType: 'document'
  },
  packshot: {
    endpoint: 'Packshots',
    resultType: 'product'
  }
}

export const SEARCH_FILTER_COMPONENT_TYPES = {
  text: {
    type: 'text'
  },
  range: {
    type: 'range',
    controls: {
      minValue: {
        value: null
      },
      maxValue: {
        value: null
      }
    }
  },
  multiselect: {
    type: 'multiselect'
  },
  daterange: {
    type: 'daterange',
    controlKeys: {
      from: 'from',
      till: 'till'
    },
    minDate: '0001-01-01'
  },
  unknown: {
    type: 'unknown'
  }
}

export const USER_ROLES = {
  user: 'User',
  admin: 'EbiAdministrator',
  super: 'SuperAdministrator'
}

export const USER_PERMISSIONS = {
  user: {
    role: 'user',
    permissions: ['read']
  },
  admin: {
    role: 'administrator',
    permissions: ['read', 'write']
  }
}

export const ADDRESS_TYPES = {
  default: 'Default',
  delivery: 'DeliveryAddress',
  invoice: 'InvoiceAddress'
}

export const EDUCATION_VARIANT_TYPES = {
  seminar: 'classroomTraining',
  webinar: 'liveWebinar',
  elearning: 'eLearning',
  podcast: 'podcast',
  recordedwebinar: 'recordedWebinar',
  learningpath: 'Lehrgang'
}

export const EDUCATION_TYPE_VARIANTS = Object.keys(EDUCATION_VARIANT_TYPES).reduce((types, tKey) => Object.assign(types, { [EDUCATION_VARIANT_TYPES[tKey]]: tKey }), {})

export const EDUCATION_VARIANT_STATES = {
  light: 'Unregistered',
  // classroomCourse status types
  danger: ['OnWaitingList', 'RegisteredCourseCancelled'],
  warning: 'RegisteredCourseNotConfirmed',
  success: 'RegisteredCourseConfirmed',
  // elearning status types
  open: 'ActivityInProgress',
  approved: 'ActivityCompleted'
}

export const EDUCATION_STATE_VARIANTS = Object.keys(EDUCATION_VARIANT_STATES).reduce((types, tKey) => EDUCATION_VARIANT_STATES[tKey].constructor === Array ? EDUCATION_VARIANT_STATES[tKey].reduce((subTypes, subTypeKey) => Object.assign(types, { [subTypeKey]: tKey }), types) : Object.assign(types, { [EDUCATION_VARIANT_STATES[tKey]]: tKey }), {})

export const EDUCATION_VARIANT_BOOKINGS = {
  success: 'bookable',
  warning: 'almostfullybooked',
  danger: 'fullybooked',
  info: 'waitinglist',
  secondary: ['past', 'cancelled', 'registrationdeadlinedue', 'noregistration']
}

export const EDUCATION_BOOKING_VARIANTS = Object.keys(EDUCATION_VARIANT_BOOKINGS).reduce((types, tKey) => EDUCATION_VARIANT_BOOKINGS[tKey].constructor === Array ? EDUCATION_VARIANT_BOOKINGS[tKey].reduce((subTypes, subTypeKey) => Object.assign(types, { [subTypeKey]: tKey }), types) : Object.assign(types, { [EDUCATION_VARIANT_BOOKINGS[tKey]]: tKey }), {})

export const CHANGEREQUEST_STATES = {
  open: 'Open',
  replaced: 'Replaced',
  approved: 'Approved',
  declined: 'Declined',
  closed: 'Closed'
}

export const CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP = {
  input: {
    type: 'StringValueAttribute',
    component: 'control-input',
    baseValue: ''
  },
  integer: {
    type: 'IntValueAttribute',
    component: 'control-input',
    baseValue: ''
  },
  select: {
    type: 'SingleSelectValueAttribute',
    component: 'control-select',
    baseValue: ''
  },
  singlerelation: {
    type: 'SingleRelationValueAttribute',
    component: 'control-select',
    baseValue: ''
  },
  textarea: {
    type: 'MultiLineStringValueAttribute',
    component: 'control-textarea',
    baseValue: ''
  },
  checkbox: {
    type: 'MultiSelectValueAttribute',
    component: 'control-checkbox',
    baseValue: []
  },
  multirelation: {
    type: 'MultiRelationValueAttribute',
    component: 'control-multiselect',
    baseValue: []
  },
  password: {
    type: 'PasswordValueAttribute',
    component: 'control-input',
    baseValue: '',
    controlType: 'password',
    validators: ['requiredValidator'],
    /*
      https://docs.microsoft.com/en-us/aspnet/core/security/authentication/identity-configuration?view=aspnetcore-5.0#password
      Passord Regex:
      ^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]))([a-zA-Z\d]|[^a-zA-Z\d\s]){6,}$
      1 lowercase
      1 uppercase
      1 digit
      1 nonalphanumeric => ~`!@#$%^&*()_-+={}[]|\:;<,>.?/
        but no space-characters \s => space\r\n\t\f\v
      6 digits or longer
    */
    customValidators: {
      lowercase: {
        validation: value => /^(?=.*[a-z]).+$/.test(value)
      },
      uppercase: {
        validation: value => /^(?=.*[A-Z]).+$/.test(value)
      },
      digit: {
        validation: value => /^(?=.*\d).+$/.test(value)
      },
      nonalphanumeric: {
        validation: value => /^(?=.*[^a-zA-Z\d\s]).+$/.test(value)
      },
      nonundefined: {
        validation: value => /^([a-zA-Z\d]|[^a-zA-Z\d\s])+$/.test(value)
      },
      minLength: {
        validation: validators.minLength,
        parameters: 6
      }
    }
  },
  passwordConfirm: {
    type: 'CustomPasswordValueAttribute',
    component: 'control-input',
    baseValue: '',
    controlType: 'password'
    // Do this in your View and return the property you want to check with
    // import { getNamedContext } from '@/assets/js/helper/vm'
    // customValidators: {
    //   sameAsPassword: {
    //     validation: validators.sameAs,
    //     parameters (control = {}) {
    //       const context = getNamedContext('Formular', this)
    //       // if context is null, return either null (validation returns false), or control.value (validation returns true)
    //       return context ? context.form.controls.password.control.value : control.value
    //     }
    //   }
    // }
  },
  files: {
    type: 'MultiFileValueAttribute',
    component: 'control-files',
    baseValue: [],
    requiresHardChange: true
  },
  boolean: {
    type: 'BooleanValueAttribute',
    component: 'control-checkbox',
    baseValue: false
  },
  unknown: {
    type: 'UnknownValueAttribute',
    component: 'control-unknown',
    baseValue: ''
  }
}

export const CONTROL_DISCRIMINATOR_TO_COMPONENT_MAP = Object.keys(CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP).reduce((types, tKey) => Object.assign(types, { [CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP[tKey].type]: Object.assign({}, CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP[tKey], { type: tKey }) }), {})

export const CONTROL_CHECK_TO_DISCRIMINATOR_MAP = {
  equal: {
    type: 'VisibleWhenEqual',
    check: (a, b) => a === b
  }
}

export const CONTROL_DISCRIMINATOR_TO_CHECK_MAP = Object.keys(CONTROL_CHECK_TO_DISCRIMINATOR_MAP).reduce((checks, cKey) => Object.assign(checks, { [CONTROL_CHECK_TO_DISCRIMINATOR_MAP[cKey].type]: Object.assign({}, CONTROL_CHECK_TO_DISCRIMINATOR_MAP[cKey], { type: cKey }) }), {})

export const REGISTRATION_FORMDATA_DEFAULTS = {
  user: {},
  userAddresses: [],
  professions: [],
  userLocations: [],
  locationsWithAddresses: [],
  login: {}
}

export const LOCATIONSEARCH_FORMDATA_DEFAULTS = {
  userLocations: [],
  locationsWithAddresses: []
}

export const LOCATIONSSEARCH_PROPERTY_OPTIONS = {
  formDataProperites: {
    existing: 'userLocations',
    newcomer: 'locationsWithAddresses'
  },
  idProperties: {
    existing: 'locationId',
    newcomer: 'formId'
  },
  newcomerProperties: {
    location: {},
    addresses: []
  }
}
