import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'

const GLOBALSEARCH_RESULTTYPES = {
  WebsiteContents: {
    maxResults: 6,
    row: 'content',
    urlType: null
  },
  Files: {
    maxResults: 6,
    row: 'content',
    urlType: null
  },
  Products: {
    maxResults: 4,
    row: 'product',
    urlType: 'product'
  },
  MarketingProducts: {
    maxResults: 4,
    row: 'product',
    urlType: 'marketingproduct'
  },
  Events: {
    maxResults: 4,
    row: 'product',
    urlType: 'education'
  }
}

const GLOBALSEARCH_RESULTTYPE_KEYS = Object.keys(GLOBALSEARCH_RESULTTYPES)
const PAGE_SIZE = GLOBALSEARCH_RESULTTYPE_KEYS.reduce((pageSize, tKey) => Math.max(pageSize, GLOBALSEARCH_RESULTTYPES[tKey].maxResults), 0) + 1
const INITIAL_RESULTS = GLOBALSEARCH_RESULTTYPE_KEYS
  .reduce((results, tKey) => Object.assign(results, {
    [tKey]: {
      items: [],
      hasMore: false,
      row: GLOBALSEARCH_RESULTTYPES[tKey].row,
      urlType: GLOBALSEARCH_RESULTTYPES[tKey].urlType
    }
  }), {})

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    results: INITIAL_RESULTS
  }),
  getters: base.createGetters(IS.getters, {
    get: state => state.results,
    getContents: state => Object.keys(state.results)
      .filter(rKey => state.results[rKey].row === 'content')
      .reduce((resultTypes, rKey) => Object.assign(resultTypes, { [rKey]: state.results[rKey] }), {}),
    getProducts: state => Object.keys(state.results)
      .filter(rKey => state.results[rKey].row === 'product')
      .reduce((resultTypes, rKey) => Object.assign(resultTypes, { [rKey]: state.results[rKey] }), {}),
    getEvents: state => Object.keys(state.results)
      .filter(rKey => state.results[rKey].row === 'event')
      .reduce((resultTypes, rKey) => Object.assign(resultTypes, { [rKey]: state.results[rKey] }), {}),
    getFiles: state => Object.keys(state.results)
      .filter(rKey => state.results[rKey].row === 'file')
      .reduce((resultTypes, rKey) => Object.assign(resultTypes, { [rKey]: state.results[rKey] }), {})
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, results = {}) {
      Object.keys(state.results)
        .forEach(rKey => {
          const resultType = results[rKey] || []
          state.results[rKey].items = resultType.slice(0, GLOBALSEARCH_RESULTTYPES[rKey].maxResults)
          state.results[rKey].hasMore = resultType.length > GLOBALSEARCH_RESULTTYPES[rKey].maxResults
        })
    },
    reset (state) {
      Object.keys(state.results)
        .forEach(rKey => {
          state.results[rKey].items = []
          state.results[rKey].hasMore = false
        })
    }
  }),
  actions: base.createActions(IS.actions, {
    searchByQuery ({ state, getters, commit, dispatch, rootGetters }, { query = '' }) {
      const isKey = 'GlobalSearch/searchByQuery'

      commit('setLoading', { key: isKey, loading: true, initial: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'post',
          url: 'search/api/GlobalSearch/SearchByQuery',
          data: {
            query,
            page: 1,
            pageSize: PAGE_SIZE,
            language: rootGetters['gui/getLanguage']
          }
        })
          .then(response => {
            commit('set', response.data)
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    }
  })
}
