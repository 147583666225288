import { TOASTER_OPTIONS } from '@/assets/js/config/client'

import Vue from 'vue'
import { instances } from '../core'

function requestSuccess (config) {
  // TODO: add logic
  return config
}

function requestError (error) {
  // TODO: add logic
  if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(error.message, TOASTER_OPTIONS.api.options.error)
  return Promise.reject(error)
}

function responseSuccess (response) {
  // TODO: add logic
  if (response.isAxiosError) {
    if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(`${response.config.method.toUpperCase()} ${response.config.url}: ${response.message}`, TOASTER_OPTIONS.api.options.error)
    return Promise.reject(response)
  }

  return response
}

function responseError (error) {
  // TODO: add logic
  if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(error.message, TOASTER_OPTIONS.api.options.error)
  return Promise.reject(error)
}

Object.values(instances)
  .forEach(i => {
    i.interceptors.request.use(
      requestSuccess,
      requestError,
      { synchronous: true }
    )

    i.interceptors.response.use(
      responseSuccess,
      responseError,
      { synchronous: true }
    )
  })
