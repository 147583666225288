export function throttler (fn = null, throttle = 0) {
  let time = Date.now()

  return function () {
    if (time + throttle - Date.now() < 0) {
      fn()
      time = Date.now()
    }
  }
}

export default {
  throttler
}
