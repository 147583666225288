<script>
import { MIXIN_VIEWPORT_THROTTLE_TIME } from '@/constants'
import { BREAKPOINTSEXT } from '@/assets/js/modules/scss-variables'

import { throttler } from '@/assets/js/helper/event'

const MOBILEFIRST_BREAKPOINT_MAP = Object.keys(BREAKPOINTSEXT.mobilefirst.breakpoints).map(bKey => ({ key: bKey, value: BREAKPOINTSEXT.mobilefirst.breakpoints[bKey] })).reverse()
const DESKTOPFIRST_BREAKPOINT_MAP = Object.keys(BREAKPOINTSEXT.desktopfirst.breakpoints).map(bKey => ({ key: bKey, value: BREAKPOINTSEXT.desktopfirst.breakpoints[bKey] }))

export default {
  data () {
    return {
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      page: {
        loaded: false,
        height: document.documentElement.scrollHeight,
        scrollTop: document.documentElement.scrollTop,
        scrollBottom: document.documentElement.scrollTop + window.innerHeight,
        scrollDirection: null,
        scrollingDown: false,
        scrollingUp: false
      },
      header: {
        height: null,
        inViewportHeight: null
      },
      // mobilefirst tablet { key, value } shorthand
      mt: BREAKPOINTSEXT.mobilefirst.tablet,
      // mobilefirst desktop { key, value } shorthand
      md: BREAKPOINTSEXT.mobilefirst.desktop,
      // desktopfirst tablet { key, value } shorthand
      dt: BREAKPOINTSEXT.desktopfirst.tablet,
      // desktopfirst mobile { key, value } shorthand
      dm: BREAKPOINTSEXT.desktopfirst.mobile
    }
  },
  computed: {
    mobilefirstBreakpoint () {
      return MOBILEFIRST_BREAKPOINT_MAP.find(b => b.value <= this.viewport.width)
    },
    desktopfirstBreakpoint () {
      return DESKTOPFIRST_BREAKPOINT_MAP.find(b => b.value > this.viewport.width)
    },
    isMobile () {
      return this.viewport.width < BREAKPOINTSEXT.mobilefirst.tablet.value
    },
    isTablet () {
      return this.viewport.width >= BREAKPOINTSEXT.mobilefirst.tablet.value && this.viewport.width < BREAKPOINTSEXT.mobilefirst.desktop.value
    },
    isDesktop () {
      return this.viewport.width > BREAKPOINTSEXT.desktopfirst.tablet.value
    }
  },
  created () {
    const updateScrolldirectionThrottler = throttler(updateScrolldirection.bind(this), MIXIN_VIEWPORT_THROTTLE_TIME)

    window.addEventListener('load', () => {
      updateData.bind(this)()
      updateScrollposition.bind(this)()

      if (this.page.scrollTop > 0) {
        this.page.scrollDirection = null
        updateScrolldirection.bind(this)()
      }

      updateHeaderHeight.bind(this)()

      this.page.loaded = true
    })

    window.addEventListener('resize', () => {
      if (this.page.loaded) {
        updateData.bind(this)()
        updateScrollposition.bind(this)()
        updateHeaderHeight.bind(this)()
      }
    })

    window.addEventListener('scroll', () => {
      if (this.page.loaded) {
        updateData.bind(this)()
        updateScrollposition.bind(this)()
        updateScrolldirectionThrottler()
        updateHeaderHeight.bind(this)()
      }
    })
  }
}

function updateData () {
  this.viewport.width = window.innerWidth
  this.viewport.height = window.innerHeight

  this.page.scrollDirection = this.page.scrollTop < document.documentElement.scrollTop ? 'scrolling-down' : this.page.scrollTop > document.documentElement.scrollTop ? 'scrolling-up' : null
  this.page.height = document.documentElement.scrollHeight
  this.page.scrollTop = document.documentElement.scrollTop
  this.page.scrollBottom = document.documentElement.scrollTop + window.innerHeight
}

function updateScrollposition () {
  const existingClasses = Array.from(document.documentElement.classList)
  let addClasses = []
  let removeClasses = ['scroll-top', 'scroll-bottom', 'scrolling-in-between']

  if (this.page.scrollTop === 0) {
    addClasses.push('scroll-top')
  } else if (this.page.scrollBottom === this.page.height) {
    addClasses.push('scroll-bottom')
  } else {
    addClasses.push('scrolling-in-between')
  }

  removeClasses = removeClasses.filter(c => !addClasses.includes(c) && existingClasses.includes(c))
  addClasses = addClasses.filter(c => !existingClasses.includes(c))

  if (removeClasses) document.documentElement.classList.remove(...removeClasses)
  if (addClasses) document.documentElement.classList.add(...addClasses)
}

function updateScrolldirection () {
  let existingClasses = Array.from(document.documentElement.classList)
  let addClasses = [].concat(this.page.scrollDirection || [])
  let removeClasses = ['scrolling-down', 'scrolling-up']

  removeClasses = removeClasses.filter(c => !addClasses.includes(c) && existingClasses.includes(c))
  addClasses = addClasses.filter(c => !existingClasses.includes(c))

  if (removeClasses) document.documentElement.classList.remove(...removeClasses)
  if (addClasses) document.documentElement.classList.add(...addClasses)

  existingClasses = Array.from(document.documentElement.classList)

  this.page.scrollingDown = existingClasses.find(c => c === 'scrolling-down')
  this.page.scrollingUp = existingClasses.find(c => c === 'scrolling-up')
}

function updateHeaderHeight () {
  const header = document.querySelector('#app > header')
  const bounds = header ? header.getBoundingClientRect() : {}
  const headerScrollHeight = bounds.height || 0
  const headerTopOffset = bounds.top || 0

  this.header.height = headerScrollHeight
  this.header.inViewportHeight = headerScrollHeight + headerTopOffset
}
</script>
