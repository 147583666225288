const LANGUAGES = process.env.VUE_APP_LANGUAGES_AVAILABLE.split(',')

export const AVAILABLE_LANGUAGES = {
  exact: LANGUAGES,
  loose: LANGUAGES.map(lang => lang.substr(0, 2))
}

const DOCUMENT_LANGUAGE = {
  exact: document.documentElement.lang,
  loose: document.documentElement.lang.substr(0, 2)
}

const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0]

export const INITIAL_LANGUAGE = getInitialLanguage()

export const AVAILABLE_DATE_I18N = AVAILABLE_LANGUAGES.loose.reduce((locales, lKey) => Object.assign(locales, { [lKey]: require(`date-fns/locale/${lKey}/index.js`) }), {})

export default {
  AVAILABLE_LANGUAGES,
  INITIAL_LANGUAGE,
  AVAILABLE_DATE_I18N
}

function getInitialLanguage () {
  let initialLanguage = null

  if (AVAILABLE_LANGUAGES.exact.includes(DOCUMENT_LANGUAGE.exact)) {
    // Get language by exact match ("{language}-{Country}" e.g. "de-CH")
    initialLanguage = DOCUMENT_LANGUAGE.exact
  } else if (AVAILABLE_LANGUAGES.loose.includes(DOCUMENT_LANGUAGE.loose)) {
    // Get language by loose match ("{language}" e.g. "de")
    initialLanguage = AVAILABLE_LANGUAGES.exact[AVAILABLE_LANGUAGES.loose.findIndex(lang => lang === DOCUMENT_LANGUAGE.loose)]
  } else {
    // Get default
    initialLanguage = DEFAULT_LANGUAGE
  }

  return initialLanguage
}
