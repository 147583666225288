import { STOREMODULE_ENDPOINT_MAP } from '@/assets/js/config/server'

import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    educations: [],
    proofs: [],
    events: []
  }),
  getters: base.createGetters(IS.getters, {
    get: state => state.educations,
    getProofs: state => state.proofs
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, { educations = [], events = [], activityTypes = [] }) {
      state.educations = educations
        .map(education => {
          const foundEvent = events.find(event => event.activityId === education.activityId && event.eventId === education.eventId)

          if (foundEvent !== undefined) {
            return Object.assign(education, {
              _event: foundEvent,
              _activityType: activityTypes.find(activityType => activityType.value === foundEvent.eventType)
            })
          }

          return education
        })
    },
    setProofs (state, proofs = []) {
      state.proofs = proofs
    }
  }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINT_MAP.usereducations, IS.actions, {
    get (serverEntityName = '') {
      const isKey = `${serverEntityName}/get`
      const isKeyEvents = `${serverEntityName}/getEvents`
      const isKeyActivityTypes = `${serverEntityName}/getActivityTypes`

      return function ({ state, getters, commit, dispatch }) {
        commit('setLoading', { key: isKey, loading: true, initial: state.educations.length === 0 })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/databridge/api/${serverEntityName}`
          })
            .then(educationProfileResponse => {
              const educations = educationProfileResponse.data.result.openActivities || []
              const entityIds = educations.map(a => ({ activityId: a.activityId, eventId: a.eventId }))

              commit('setProofs', educationProfileResponse.data.result.educationProofs)
              commit('setLoading', { key: isKeyEvents, loading: true, initial: state.events.length === 0 })

              http({
                method: 'post',
                url: '/catalog/api/Events/many',
                data: { entityIds }
              })
                .then(eventsResponse => {
                  commit('setLoading', { key: isKeyActivityTypes, loading: true, initial: state.events.length === 0 })

                  http({
                    method: 'get',
                    url: '/catalog/api/EventActivityTypes/all'
                  })
                    .then(eventActivityTypesResponse => {
                      commit('set', { educations, events: eventsResponse.data.result, activityTypes: eventActivityTypesResponse.data.result })

                      resolve(getters.get)
                    })
                    .finally(() => {
                      commit('setLoading', { key: isKeyActivityTypes, loading: false })
                    })
                })
                .finally(() => {
                  commit('setLoading', { key: isKeyEvents, loading: false })
                })
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    }
  })
}
