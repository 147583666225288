<script>
const scrollbarSuppressors = {}

export default {
  created () {
    window.addEventListener('load', event => updateScrollbarSuppression.bind(this)(event))
    window.addEventListener('resize', event => updateScrollbarSuppression.bind(this)(event))
    this.$root.$on('scrollbar:toggle', payload => registerScrollbarSuppressor.bind(this)(payload))
  }
}

function registerScrollbarSuppressor ({ key = 'default', state = false, from = null, till = null }) {
  scrollbarSuppressors[key] = {
    key,
    state,
    from: from || 0,
    till: till || Infinity
  }

  updateScrollbarSuppression()
}

function updateScrollbarSuppression () {
  const suppressState = Object.values(scrollbarSuppressors)
    .filter(s => s.from <= window.innerWidth && s.till >= window.innerWidth)
    .some(s => s.state === true)

  window.document.documentElement.classList[suppressState ? 'add' : 'remove']('suppress-scrollbar')
}
</script>
