import language from '@/assets/js/config/language'
import i18n from '@/$plugins/i18n/core'

const CONFIGURATION = window.guiConfiguration || {}

export default {
  namespaced: true,
  state: {
    language: language.INITIAL_LANGUAGE,
    languagesAvailable: language.AVAILABLE_LANGUAGES,
    dateLanguage: language.AVAILABLE_DATE_I18N[language.INITIAL_LANGUAGE.substr(0, 2)],
    dateLanguageAvailable: language.AVAILABLE_DATE_I18N,
    number: {
      decimalSeparator: i18n.n(1.1, 'decimal').replace(/[0-9]+/g, '').trim(),
      thousandSeparator: i18n.n(1000, 'decimal').replace(/[0-9]+/g, '').trim()
    },
    localUrls: CONFIGURATION.localUrls || [],
    productBaseUrl: CONFIGURATION.productBaseUrl || '',
    educationBaseUrl: CONFIGURATION.educationBaseUrl || '',
    marketingproductBaseUrl: CONFIGURATION.marketingproductBaseUrl || '',
    packshotBaseUrl: CONFIGURATION.packshotBaseUrl || ''
  },
  getters: {
    getLanguage: state => state.language,
    getLooseLanguage: state => state.language.substr(0, 2),
    getLanguagesAvailable: state => state.languagesAvailable,
    getDateLanguage: state => state.dateLanguage,
    getDateLanguageAvailable: state => state.dateLanguageAvailable,

    getLocalUrls: state => state.localUrls,
    getBaseUrl: state => (type = '', urlSlug = '') => `${state[`${type}BaseUrl`] ? state[`${type}BaseUrl`] + '/' : ''}${urlSlug}`,

    getNumber: state => state.number
  },
  mutations: {},
  actions: {}
}
