import { STOREMODULE_ENDPOINT_MAP } from '@/assets/js/config/server'

// import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import CHANGE_REQUESTS from '../base/change-requests'
import SEARCH from '../base/entity-search'
import MAINENTITY from '../base/entity-main'

export default {
  namespaced: true,
  state: base.createState(IS.state, CHANGE_REQUESTS.state, SEARCH.state, MAINENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, CHANGE_REQUESTS.getters, SEARCH.getters, MAINENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, CHANGE_REQUESTS.mutations, SEARCH.mutations, MAINENTITY.mutations, { /* define custom mutations */ }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINT_MAP.locations, IS.actions, CHANGE_REQUESTS.actions, SEARCH.actions, MAINENTITY.actions, { /* define custom actions */ })
}
