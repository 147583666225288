import Vue from 'vue'
import Vuex from 'vuex'

import configuration from './modules/configuration'
import validators from './modules/validators'
import gui from './modules/gui'

import search from './modules/search'
import globalsearch from './modules/globalsearch'

import profile from './modules/profile'

import services from './modules/services'

import locations from './modules/locations'
import locationaddresses from './modules/location-addresses'

import users from './modules/users'
import useraddresses from './modules/user-addresses'
import userprofessions from './modules/user-professions'
import userservices from './modules/user-services'
import usereducations from './modules/user-educations'
import userlocations from './modules/user-locations'

import shoppingcart from './modules/shoppingcart'
import packshotlistcart from './modules/packshotlistcart'
import checkout from './modules/checkout'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    configuration,
    gui,
    profile,
    validators,

    search,
    globalsearch,

    services,

    locations,
    locationaddresses,

    users,
    useraddresses,
    userprofessions,
    userservices,
    usereducations,
    userlocations,

    shoppingcart,
    packshotlistcart,
    checkout
  }
})
