export const TOASTER_OPTIONS = {}

export const CLASS_TABLE_GLOBAL_FIELD_EXCLUDES = [
  'id',
  '_hasDetails',
  '_isDetails',
  '_showDetails',
  '_showDetailsHook',
  '_isHidden',
  '_rowVariant'
]

export const CLASS_TABLE_FIELD_KEY_ALL = '~all~'

export const CLASS_TABLE_FIELD_KEY_UNKNOWN = '~unknown~'

export const CLASS_TABLE_FIELD_KEYS_UNSORTABLE = [
  '$toggleDetails',
  '$decline',
  '$accept',
  '$kill'
]

export const CHANGEREQUEST_STATEBADGE_OUTLINE = {
  open: true,
  replaced: false,
  approved: false,
  declined: false,
  closed: false
}

export const ENTITY_CONTROL_HIDDEN_BY_PROPERTY_KEYS = [
  'hidden'
]

export const PAGINATION_INITIAL_VALUES = {
  page: 1,
  pageSize: 50
}

export const SEARCH_INITIAL_VALUES = {
  page: 1,
  pageSize: 30
}

export const SEARCH_FILTER_EXPANDED_DEFAULT = true

export const SEARCH_FILTER_EMPTY_CONTROL_VALUES = {
  text: '',
  multiselect: false,
  daterange: null,
  range: null
}

export const SEARCH_FILTER_URL_PARAMETER_SEPARATOR = ';'

export const MAX_COLUMNS_COUNT = 4

export const SINGLE_COLUMN_WIDTHS = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }
export const BASE_COLUMN_WIDTHS = { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }
export const ASIDE_COLUMN_WIDTHS = { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }

export const ORDERED_ENTITYPROPERTY_ROWGROUPS = [
  {
    zip: '4',
    city: '8'
  }
]

export const TRANSLATION_DEFAULT_REPLACER = {
  br: '<br/>'
}

export const REGISTRATION_STEPS = {
  user: {
    showInNavigation: true,
    validation: (data, meta) => Object.keys(data.user).length > 0 && data.userAddresses.length > 0 && data.professions.length > 0
  },
  locations: {
    showInNavigation: true,
    validation: (data, meta) => meta.registerWithoutAddresses || (data.userLocations.length > 0 || data.locationsWithAddresses.length > 0)
  },
  login: {
    showInNavigation: true,
    isSubmitStep: true,
    validation: (data, meta) => Object.keys(data.login).length > 0
  },
  completion: {
    showInNavigation: false,
    validation: null
  }
}

export const LOCATIONSEARCH_FORM_OPTIONS = {
  validation: (data, meta) => data.userLocations.length > 0 || data.locationsWithAddresses.length > 0
}

export const TEASER_IMAGE_TYPES = ['default', 'product', 'packshot', 'marketingproduct']
