import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    profile: {
      user: Object.assign({
        clientId: null,
        userId: null,
        userName: null,
        email: null,
        roles: [],
        securityLevel: null,
        approvalStatus: null
      }, window.userProfile || {}),

      locations: Object.assign({
        userLocations: [],
        locationRequests: [],
        activeLocationId: null
      }, window.userLocations || {})
    }
  },
  getters: {
    get: state => state.profile,
    getUserId: state => state.profile.user.userId,
    getLocations: state => state.profile.locations,
    getActiveLocationId: state => state.profile.locations.activeLocationId || (state.profile.locations.userLocations[0] || {}).locationId || null
  },
  mutations: {
    setLocations (state, locations = []) {
      const userLocations = locations.filter(l => l.isAccepted === true)
      const locationRequests = locations.filter(l => l.isAccepted === false)

      Vue.set(state.profile.locations, 'userLocations', userLocations)
      Vue.set(state.profile.locations, 'locationRequests', locationRequests)
    }
  },
  actions: {}
}
