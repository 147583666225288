import Vue from 'vue'
import store from '@/$plugins/store/core'

// Custom
import './components'
import './modules/browser-detection'
import './modules/scroll-spy'

// Global Vue Mixins
import Translations from '@/components/mixins/global/Translations'

Vue.mixin(Translations)

export default function App (initCallback = function () {}) {
  const APP_BOOTSTRAP = [
    { method: store.dispatch, params: ['validators/get'] }
  ]

  APP_BOOTSTRAP
    .forEach(action => {
      action.method(...action.params)
        .finally(() => {
          action.done = true

          if (APP_BOOTSTRAP.every(a => a.done)) initCallback()
        })
    })
}
