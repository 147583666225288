export const ISO_CODES = process.env.VUE_APP_LANGUAGES_AVAILABLE.split(',').map(getIsoCode)
export const AVAILABLE_LANGUAGES = process.env.VUE_APP_LANGUAGES_AVAILABLE.split(',').map(getIsoCountryCode)
const DOCUMENT_LANGUAGE = getIsoCountryCode(document.documentElement.lang || '')
const BROWSER_LANGUAGE = getIsoCountryCode(window.navigator.language || '')
export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0]
export const INITIAL_LANGUAGE = getInitialLanguage()
export const DATETIME_LOCALES = AVAILABLE_LANGUAGES.reduce((locales, lKey) => Object.assign(locales, { [lKey]: require(`date-fns/locale/${getIsoCode(lKey)}/index.js`) }), {})

export const MESSAGES = AVAILABLE_LANGUAGES
  .reduce((messages, language) => Object.assign(messages, { [language]: require(`./modules/messages/${language}.js`).default }), {})

export const NUMBERFORMATS = AVAILABLE_LANGUAGES
  .reduce((formats, language) => Object.assign(formats, { [language]: require(`./modules/numberformats/${language}.js`).default }), {})

export const DATETIMEFORMATS = AVAILABLE_LANGUAGES
  .reduce((formats, language) => Object.assign(formats, { [language]: require(`./modules/datetimeformats/${language}.js`).default }), {})

export function getIsoCountryCode (language = '') {
  return `${language.match(/^[^-]+/)[0].toLowerCase()}-${language.match(/[^-]+$/)[0].toUpperCase()}`
}

export function getIsoCode (language = '') {
  return language.substr(0, 2).toLowerCase()
}

export function getIsoLanguageByCountryCode (countryCode = '') {
  const isoCountryCode = getIsoCountryCode(countryCode)
  return ISO_CODES.find(isoLanguage => isoCountryCode.indexOf(isoLanguage) === 0)
}

export function getCountryCodeByIsoLanguage (language = '') {
  const isoLanguage = getIsoCode(language)
  return AVAILABLE_LANGUAGES.find(isoCountryCode => isoCountryCode.indexOf(isoLanguage) === 0)
}

function getInitialLanguage () {
  let initial = DEFAULT_LANGUAGE

  if (AVAILABLE_LANGUAGES.includes(DOCUMENT_LANGUAGE)) {
    initial = DOCUMENT_LANGUAGE
  } else if (AVAILABLE_LANGUAGES.includes(BROWSER_LANGUAGE)) {
    initial = BROWSER_LANGUAGE
  }

  updateDocumentLanguage(initial)

  return initial
}

export function setLanguage (language = '', fallback = '', updateDocument = true) {
  let update = DEFAULT_LANGUAGE
  const isoLanguage = getIsoCode(language)
  const isoFallback = getIsoCode(fallback)

  if (AVAILABLE_LANGUAGES.includes(isoLanguage)) {
    update = isoLanguage
  } else if (AVAILABLE_LANGUAGES.includes(isoFallback)) {
    update = isoFallback
    console.warn(`Language "${language}" not available!\nSystem falls back to "${fallback}" instead.`)
  } else {
    console.warn(`Language "${language}" and Fallback "${fallback}" not available!\nSystem falls back to default instead.`)
  }

  if (updateDocument) updateDocumentLanguage(update)

  return update
}

function updateDocumentLanguage (language = '') {
  document.querySelector('html').setAttribute('lang', getIsoCode(language))
}

export default {
  ISO_CODES,
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
  INITIAL_LANGUAGE,
  MESSAGES,
  NUMBERFORMATS,
  getIsoCountryCode,
  getIsoCode,
  getIsoLanguageByCountryCode,
  getCountryCodeByIsoLanguage,
  getInitialLanguage,
  setLanguage
}
