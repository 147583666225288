import EXPORT from '@/assets/css/config/variables-export.scss'

const scss = {
  base: {
    black: '#000',
    white: '#fff'
  },
  colors: {
    base: {},
    gray: {},
    theme: {}
  },
  grid: {},
  vars: {}
}

for (const vKey in EXPORT) {
  const color = vKey.match(/^color-(base|gray|theme)-/)
  const grid = vKey.match(/^grid-/)
  const base = [].concat(color || [], grid || [])[0]

  const key = vKey.replace(base, '')
  const value = createValue(EXPORT[vKey])

  if (color) {
    scss.colors[color[1]][key] = value
  } else if (grid) {
    scss.grid[key] = value
  } else {
    scss.vars[key] = value
  }
}

export const BREAKPOINTS = scss.grid['breakpoint-keys']
  .reduce((breakpoints, bKey) => Object.assign(breakpoints, { [bKey]: scss.grid[`breakpoint-${bKey}-down`].value }), {})

export const BREAKPOINTSEXT = {
  mobilefirst: {
    breakpoints: scss.grid['breakpoint-ext-keys'].reduce((breakpoints, bKey) => Object.assign(breakpoints, { [bKey]: scss.grid[`breakpoint-ext-mobilefirst-${bKey}`].value }), {}),
    tablet: {
      key: scss.vars['breakpoint-ext-mobilefirst-tablet-key'],
      value: scss.vars['breakpoint-ext-mobilefirst-tablet'].value
    },
    desktop: {
      key: scss.vars['breakpoint-ext-mobilefirst-desktop-key'],
      value: scss.vars['breakpoint-ext-mobilefirst-desktop'].value
    }
  },
  desktopfirst: {
    breakpoints: scss.grid['breakpoint-ext-keys'].reduce((breakpoints, bKey) => Object.assign(breakpoints, { [bKey]: scss.grid[`breakpoint-ext-desktopfirst-${bKey}`].value }), {}),
    tablet: {
      key: scss.vars['breakpoint-ext-desktopfirst-tablet-key'],
      value: scss.vars['breakpoint-ext-desktopfirst-tablet'].value
    },
    mobile: {
      key: scss.vars['breakpoint-ext-desktopfirst-mobile-key'],
      value: scss.vars['breakpoint-ext-desktopfirst-mobile'].value
    }
  }
}

export default scss

function createValue (value) {
  return value.includes(',') ? valueToArray(value) : valueToNumber(value)

  function valueToArray (v) {
    return v
      .replace(/,\s?/g, ',')
      .split(',')
      .map(valueToNumber)
  }

  function valueToNumber (value) {
    return /^([\d.]){1,}(([a-zA-Z]){2,}|%)?$/.test(value) ? { value: parseFloat(value), unit: value.replace(/^\d+/g, '') } : value
  }
}
