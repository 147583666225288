import { PAGINATION_INITIAL_VALUES } from '@/assets/js/config/client'

import Vue from 'vue'
import http from '@/$plugins/http'

import base from './index'

import { normalize, unwrap } from '@/assets/js/helper/entity'
import {
  filterIdGenerator,
  sortIdGenerator,
  filterMapper,
  sortMapper,
  filterControlResetter,
  searchParameters,
  filterParameters,
  initialParameterSetter,
  urlParameterKeySetter,
  urlParameterSetter
} from '@/assets/js/helper/search'

export default {
  namespaced: true,
  state: {
    filters: [],
    sorts: [],
    decreasedEntities: [],
    decreasedEntitiesAllEverLoaded: []
  },
  getters: {
    filters: state => state.filters,
    sorts: state => state.sorts,
    getActiveSort: state => state.sorts.find(s => s.isActive),
    getDecreasedEntities: state => state.decreasedEntities,
    getAllEverLoadedDecreasedEntities: state => state.decreasedEntitiesAllEverLoaded
  },
  mutations: {
    setFilters (state, filters = []) {
      filters
        .forEach(filter => {
          const FILTER_ID = filterIdGenerator(filter)
          const updateIndex = state.filters.findIndex(f => f.id === FILTER_ID)
          const newIndex = state.filters.length
          const existingFilter = state.filters[updateIndex] || {}

          Vue.set(state.filters, updateIndex >= 0 ? updateIndex : newIndex, filterMapper(filter, existingFilter, state.urlParameters))
        })

      state.filters.sort((a, b) => a.sortOrder - b.sortOrder)
    },
    setSorts (state, sorts = []) {
      sorts
        .forEach(sort => {
          const SORT_ID = sortIdGenerator(sort)
          const updateIndex = state.sorts.findIndex(s => s.id === SORT_ID)
          const newIndex = state.sorts.length
          const existingSort = state.sorts[updateIndex] || {}

          Vue.set(state.sorts, updateIndex >= 0 ? updateIndex : newIndex, sortMapper(sort, existingSort))
        })

      state.sorts.sort((a, b) => a.sortOrder - b.sortOrder)
    },
    append (state, { key, entities = [] }) {
      state.entities = state.entities
        .concat(entities
          .map(entity => {
            const normalizedEntity = normalize(entity, key)
            if (normalizedEntity.value.id === state.self.value.id) state.self = normalizedEntity
            return normalizedEntity
          })
        )
    },
    setDecreasedEntities (state, entities = []) {
      state.decreasedEntities = entities.map(unwrap)
      state.decreasedEntitiesAllEverLoaded = state.decreasedEntitiesAllEverLoaded.concat(state.decreasedEntities)
    }
  },
  actions: {
    initSearch (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, { filters = [] }) {
        initialParameterSetter(filters)
        return dispatch('searchByFilters')
      }
    },
    searchByFilters (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/searchByFilters`

      return function ({ state, getters, commit, dispatch, rootGetters }, options = {}) {
        const SEARCH_PARAMETERS = searchParameters({
          filters: state.filters,
          sorts: state.sorts,
          statistics: state.statistics
        })

        const o = base.getOptions(options, {
          language: rootGetters['gui/getLanguage'],
          filters: SEARCH_PARAMETERS.filters,
          sort: SEARCH_PARAMETERS.sorts,
          page: SEARCH_PARAMETERS.page.number,
          pageSize: SEARCH_PARAMETERS.page.size
        })

        commit('setLoading', { key: isKey, loading: true, initial: true })

        return new Promise(resolve => {
          http({
            method: 'post',
            url: `search/api/${serverEntityName}/SearchByFilters`,
            data: o.data
          })
            .then(response => {
              urlParameterKeySetter(response.data.filters)

              commit('setStatistics', response.data)
              commit('setFilters', response.data.filters)
              commit('setSorts', response.data.sorts)
              commit(options.append ? 'append' : 'set', { key: entityKey, entities: response.data.result })
              commit('gui/setSearchEntityKey', entityKey, { root: true })
              resolve(getters.get)

              urlParameterSetter(filterParameters(state.filters))
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    searchByQueryLimited (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/performSearch`

      return function ({ state, getters, commit, dispatch, rootGetters }, options = {}) {
        const o = base.getOptions(options, {
          language: rootGetters['gui/getLanguage']
        })

        commit('setLoading', { key: isKey, loading: true, initial: true })

        return new Promise(resolve => {
          http({
            method: 'post',
            url: `search/api/${serverEntityName}/SearchByQueryLimited`,
            data: o.data
          })
            .then(response => {
              commit('setDecreasedEntities', response.data.result)
              resolve(getters.getDecreasedEntities)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    setFilters (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { action: undefined }) {
        dispatch('resetPage', { action: false })

        if (options.action !== false) dispatch(options.action === undefined ? 'searchByFilters' : options.action)
      }
    },
    setActiveSort (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { sortId: null, action: undefined }) {
        const oldActiveSort = state.sorts.find(s => s.isActive) || {}
        const newActiveSort = state.sorts.find(s => s.id === options.sortId) || {}

        if (newActiveSort.id) {
          if (oldActiveSort.id === newActiveSort.id) {
            newActiveSort.isDescending = !newActiveSort.isDescending
          } else {
            oldActiveSort.isActive = false
            newActiveSort.isActive = true
          }

          dispatch('resetPage', { action: false })
        }

        if (options.action !== false) dispatch(options.action === undefined ? 'searchByFilters' : options.action)
      }
    },
    getSearchPage (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { number: undefined, action: undefined }) {
        if (options.number !== undefined) {
          state.statistics.page.number = options.number
          if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters')
        }
      }
    },
    getNextSearchPage (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { action: undefined }) {
        state.statistics.page.number += 1
        if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters', { append: true })
      }
    },
    resetFilters (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { action: undefined }) {
        if (searchParameters({ filters: state.filters }).filters.length) {
          state.filters.map(filter => Object.assign(filter, { controls: filterControlResetter(filter) }))
          dispatch('resetPage', { action: false })

          if (options.action !== false) dispatch(options.action === undefined ? 'searchByFilters' : options.action)
        }
      }
    },
    resetSorts (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { action: undefined }) {
        state.sorts.map(sort => Object.assign(sort, { isActive: sort.isDefault }))
        dispatch('resetPage', { action: false })

        if (options.action !== false) dispatch(options.action === undefined ? 'searchByFilters' : options.action)
      }
    },
    resetPage (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { action: undefined }) {
        state.statistics.page.number = PAGINATION_INITIAL_VALUES.page

        if (options.action !== false) dispatch(options.action === undefined ? 'searchByFilters' : options.action)
      }
    },
    clearSearch (serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { action: undefined }) {
        state.decreasedEntities = []
        state.decreasedEntitiesAllEverLoaded = []
      }
    }
  }
}
