import vuelidate from './core'
import './hooks/app-definition'
import customValidators from './hooks/custom-validators'
import * as builtinValidators from 'vuelidate/lib/validators'

const validators = Object.assign({}, builtinValidators, customValidators)

export {
  vuelidate as default,
  validators
}
