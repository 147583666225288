import Vue from 'vue'
import store from '@/$plugins/store'
import http from '@/$plugins/http'
import i18n from '@/$plugins/i18n'
import timeago from '@/$plugins/timeago'
import vuelidate, { validators } from '@/$plugins/vuelidate'
import bootstrap from '@/$plugins/bootstrap'
import vuecookies from 'vue-cookies'
import moment from 'moment'

import ScrollbarSuppressor from '@/components/mixins/ScrollbarSuppressor'

import clickOutside from './directives/click-outside'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

import MixinViewport from '@/components/mixins/Viewport'

import App from '@/assets/js/app.js'
import '@/assets/css/app.scss'

Vue.config.productionTip = false

Vue.directive('click-outside', clickOutside)

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDateTime', value => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY hh:mm')
  }
})

Vue.prototype.window = window
Vue.prototype.document = document

Vue.use(vuecookies)

App(() => {
  new Vue({ // eslint-disable-line no-new
    store,
    http,
    i18n,
    timeago,
    vuelidate,
    bootstrap,
    vuecookies,
    mixins: [
      MixinViewport,
      ScrollbarSuppressor
    ],
    el: '#app',
    propsData: {
      translations: window.globalTranslations || {}
    },
    data: {
      env: {
        isDevelopment: process.env.NODE_ENV === 'development',
        isProduction: process.env.NODE_ENV === 'production',
        name: process.env.ENV_NAME
      },
      validators,
      scss: SCSS_VARIABLES,
      mobile: {
        down: {
          key: SCSS_VARIABLES.vars['mobile-breakpoint-down'],
          pixel: SCSS_VARIABLES.grid[`breakpoint-${SCSS_VARIABLES.vars['mobile-breakpoint-up']}-down`].value
        },
        up: {
          key: SCSS_VARIABLES.vars['mobile-breakpoint-up'],
          pixel: SCSS_VARIABLES.grid[`breakpoint-${SCSS_VARIABLES.vars['mobile-breakpoint-up']}-up`].value
        }
      }
    },
    methods: {
      isNullOrEmpty (value) {
        return value === null || value === undefined || value === '' || value === []
      },
      isBoolean (value) {
        return typeof value === 'boolean'
      }
    },
    computed: {
      window () {
        return window
      },
      serverValidators () {
        return this.$store.getters['validators/get'].reduce((serverValidators, v) => Object.assign(serverValidators, { [v.type]: v }), {})
      }
    },
    mounted () {
      this.$el.classList.add('ready', this.env.name.toLowerCase())
      document.documentElement.classList.add(...['ready', this.env.isTouch ? 'is-touch' : null].filter(c => c))
    }
  })
})
