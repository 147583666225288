window.addEventListener('load', scrollSpy)
window.addEventListener('scroll', scrollSpy)
window.addEventListener('resize', scrollSpy)

function scrollSpy () {
  const el = document.getElementById('app')
  const totalHeight = document.documentElement.scrollHeight
  const position = document.documentElement.scrollTop
  const positionBottomOffset = window.innerHeight

  el.classList.remove('spy-t', 'spy-m', 'spy-b')

  if (position === 0) {
    el.classList.add('spy-t')
  } else if (position + positionBottomOffset === totalHeight) {
    el.classList.add('spy-b')
  } else {
    el.classList.add('spy-m')
  }
}
