import { TOASTER_OPTIONS } from '@/assets/js/config/client'

import Vue from 'vue'
import http from '@/$plugins/http'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    toogleApprove (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/toogleApprove`

      return function ({ state, getters, commit, dispatch }, entityId = null) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/customer/api/${serverEntityName}/${entityId}/toggleisapproved`
          })
            .then(response => {
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.updated'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getEntity(entityId))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    }
  }
}
