import { STOREMODULE_ENDPOINT_MAP } from '@/assets/js/config/server'

import http from '@/$plugins/http'
import { normalize, unwrap } from '@/assets/js/helper/entity'

import base from '../base'
import IS from '../base/is'
import CHANGE_REQUESTS from '../base/change-requests'
import ENTITY_APPROVE from '../base/entity-approve'
import ENTITY from '../base/entity'

export default {
  namespaced: true,
  state: base.createState(IS.state, CHANGE_REQUESTS.state, ENTITY_APPROVE.state, ENTITY.state, {
    current: normalize({})
  }),
  getters: base.createGetters(IS.getters, CHANGE_REQUESTS.getters, ENTITY_APPROVE.getters, ENTITY.getters, {
    getCurrent: state => state.current,
    getCurrentUnwrapped: state => unwrap(state.current)
  }),
  mutations: base.createMutations(IS.mutations, CHANGE_REQUESTS.mutations, ENTITY_APPROVE.mutations, ENTITY.mutations, {
    setCurrent (state, { key, entity = {} }) {
      state.current = normalize(entity, key)
    }
  }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINT_MAP.userprofessions, IS.actions, CHANGE_REQUESTS.actions, ENTITY_APPROVE.actions, ENTITY.actions, {
    getCurrent (serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getCurrent`

      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        dispatch('getDefinition')
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/customer/api/${serverEntityName}${o.by.url.from}/current`
          })
            .then(response => {
              commit('setCurrent', { key: entityKey, entity: response.data.result })
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getCurrent)
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    }
  })
}
