import Vue from 'vue'
import VueI18n from 'vue-i18n'
import startOfWeek from 'date-fns/startOfWeek'
import { INITIAL_LANGUAGE, DEFAULT_LANGUAGE, MESSAGES, NUMBERFORMATS, DATETIMEFORMATS } from './config'

Vue.use(VueI18n)

export default new VueI18n({
  locale: INITIAL_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  messages: MESSAGES,
  numberFormats: NUMBERFORMATS,
  dateTimeFormats: DATETIMEFORMATS
})

startOfWeek(new Date())
