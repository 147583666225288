import Vue from 'vue'
import Bootstrap, { BootstrapIcons } from '../core'
import { version } from 'bootstrap-vue/package.json'

Vue.$app = Vue.$app || {}

Vue.$app.bootstrap = Bootstrap
Vue.$app.bootstrap.version = version
Vue.$app.bootstrapIcons = BootstrapIcons
Vue.$app.bootstrapIcons.version = version
