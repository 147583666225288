import pluralizer from 'pluralize'

export function kebabCase (s = '') {
  return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase().replace(/^-/g, '')
}

export function camelCase (s = '') {
  return s.replace(/(\s|-)./g, v => v.replace(/(\s|-)/g, '').toUpperCase())
}

export function capitalize (s = '') {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function decapitalize (s = '') {
  return s.charAt(0).toLowerCase() + s.slice(1)
}

export function singularize (s = '') {
  return pluralizer.singular(s)
}

export function pluralize (s = '') {
  return pluralizer.plural(s)
}

export default {
  kebabCase,
  camelCase,
  capitalize,
  decapitalize,
  singularize,
  pluralize
}
