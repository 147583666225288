import Vue from 'vue'
import store from '@/$plugins/store/core'

import VueTimeago from 'vue-timeago'
import { format, formatDistanceToNow } from 'date-fns'

// For supported languages visit the folder: @/../node_modules/date-fns/locale/

Vue.use(VueTimeago, {
  name: 'timeago',
  locale: store.getters['gui/getLooseLanguage'],
  locales: store.getters['gui/getDateLanguageAvailable'],
  converter (dateString, locale, converterOptions) {
    const date = new Date(dateString)
    const now = new Date()
    // allow text format if forceDateFormat is not true and forceTextFormat is true or date is today or date is in future
    const allowTextFormat = !converterOptions.forceDateFormat && (converterOptions.forceTextFormat || date.toDateString() === now.toDateString() || date.getTime() > now.getTime())

    if (allowTextFormat) {
      return formatDistanceToNow(date, {
        locale,
        includeSeconds: converterOptions.includeSeconds,
        addSuffix: converterOptions.addSuffix !== undefined ? converterOptions.addSuffix : true
      })
    } else {
      return format(date, converterOptions.format || 'PPPPp', { locale })
    }
  }
})

store.watch(() => store.getters['gui/getLooseLanguage'], language => {
  Vue.prototype.$timeago.locale = language
})

export default VueTimeago
