export default {
  required (value) {
    if (Array.isArray(value)) return !!value.length
    if (value === undefined || value === null) return false
    // the original required function handles "value === false" as true
    // instead of this, we have to handle "value === false" as false (for singlevalue control-checkboxes)
    if (value === false) return false
    if (value instanceof Date) return !isNaN(value.getTime())
    if (typeof value === 'object') {
      for (var _ in value) { return true }
      return false
    }

    return !!String(value).length
  }
}
